export const projectsData = [
    {
        name: 'Uni Coursework',
        date: '05/05/2023',
        projectId: 'p1'
    },
    {
        name: 'Web development',
        date: '06/05/2023',
        projectId: 'p2'
    }
]